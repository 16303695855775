define('skycoin/router', ['exports', 'ember', 'skycoin/config/environment'], function (exports, _ember, _skycoinConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _skycoinConfigEnvironment['default'].locationType,
    rootURL: _skycoinConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('home');
    this.route('help');
    this.route('skycoin', { path: '/' });
    this.route('testimonials');
    this.route('create');
    this.route('gallery');
    // this.route('locations');
    this.route('locations');
    this.route('legal');
  });

  exports['default'] = Router;
});