define('skycoin/components/random-splash', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    splashIds: [1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11],

    splashIndex: computed(function () {
      var ids = this.get('splashIds'),
          randomNum = Math.floor(Math.random() * ids.length);
      return ids[randomNum];
    }),

    splashSrc: computed('splashIndex', function () {
      return '/images/s' + get(this, 'splashIndex') + '.gif';
    })
  });
});