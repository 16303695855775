define('skycoin/controllers/application', ['exports', 'ember'], function (exports, _ember) {
  var inject = _ember['default'].inject;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Controller.extend({
    routing: inject.service("-routing"),
    isSplash: computed('routing.currentRouteName', function () {
      var route = get(this, 'routing.currentRouteName');
      return route === 'skycoin';
    }),
    isHome: computed('routing.currentRouteName', function () {
      var route = get(this, 'routing.currentRouteName');
      return route === 'home';
    })
  });
});