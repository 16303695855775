define("skycoin/components/skycoin-locations-map", ["exports", "ember"], function (exports, _ember) {
  /* globals L */

  exports["default"] = _ember["default"].Component.extend({
    locations: [{
      "name": "Three Gems",
      "lat": 37.771118,
      "lon": -122.469591,
      "year": 2005,
      "public": true,
      "links": "https://deyoung.famsf.org/files/styles/sidebar_image/public/threegems_ceiling.jpg?itok=QIVdw6wy"
    }, {
      "name": "Tewlwolow Kernow",
      "lat": 50.137468,
      "lon": -5.509749,
      "year": 2013,
      "public": true,
      "links": "http://archive.jamesturrell.com/artwork/skyspace/"
    }, {
      "name": "Outside, Insight",
      "lat": 59.06472,
      "lon": 17.61542,
      "year": 2011,
      "public": true,
      "links": "http://archive.jamesturrell.com/artwork/outsideinsight/"
    }, {
      "name": "Within Without",
      "lat": -35.30163422,
      "lon": 149.1365665,
      "year": 2010,
      "public": true,
      "links": "http://archive.jamesturrell.com/artwork/withinwithout/"
    }, {
      "name": "Blue Pearl",
      "lat": 47.8006343,
      "lon": 13.0385546,
      "year": 2006,
      "public": true,
      "links": "http://archive.jamesturrell.com/artwork/bluepearl/  "
    }, {
      "name": "Hardscrabble Sky",
      "lat": 41.8712421,
      "lon": -87.6716303,
      "year": 2005,
      "public": true,
      "links": "http://archive.jamesturrell.com/artwork/hardscrabblesky/"
    }, {
      "name": "Piz Uter",
      "lat": 46.6021794,
      "lon": 9.9597806,
      "year": 2005,
      "public": true,
      "links": "http://archive.jamesturrell.com/artwork/pizuter/"
    }, {
      "name": "Second Wind",
      "lat": 36.6667,
      "lon": -6.1167,
      "year": 2005,
      "public": true,
      "links": "http://archive.jamesturrell.com/artwork/secondwind/"
    }, {
      "name": "Third Breath",
      "lat": 51.5426349,
      "lon": 7.6853114,
      "year": 2005,
      "public": true,
      "links": "http://archive.jamesturrell.com/artwork/thirdbreath/"
    }, {
      "name": "Sky Mass",
      "lat": 46.7692,
      "lon": -113.7117,
      "year": 2003,
      "public": false,
      "links": "http://archive.jamesturrell.com/artwork/skymass/"
    }, {
      "name": "Plato's Eye",
      "lat": 32.817243,
      "lon": -96.799459,
      "year": 2002,
      "public": false,
      "links": "http://jamesturrell.com/work/platoseye/"
    }, {
      "name": "Blue Pesher",
      "lat": 36.089345,
      "lon": -86.877183,
      "year": 1999,
      "public": true,
      "links": "http://jamesturrell.com/work/bluepesher/"
    }, {
      "name": "Lunette",
      "lat": 45.8205989,
      "lon": 8.8250576,
      "year": 1974,
      "public": true,
      "links": "http://archive.jamesturrell.com/artwork/lunette"
    }, {
      "name": "The Way of Color",
      "lat": 36.378228,
      "lon": -94.206323,
      "year": 2008,
      "public": true,
      "links": "http://archive.jamesturrell.com/artwork/thewayofcolor/"
    }, {
      "name": "Cat Cairn / Kielder Skyspace",
      "lat": 55.227553,
      "lon": -2.610082,
      "year": 2000,
      "public": true,
      "links": "http://www.visitkielder.com/great-outdoors/cat-cairn-the-kielder-skyspace-james-turrell-2000"
    }, {
      "name": "Celestial Vault",
      "lat": 52.063139,
      "lon": 4.21846,
      "year": 1996,
      "public": true,
      "links": "http://www.stroom.nl/nl/kor/project.php?pr_id=4616026"
    }, {
      "name": "Boullee's Eye",
      "lat": 50.8503396,
      "lon": 4.3517103,
      "year": 2003,
      "public": false,
      "links": "http://archive.jamesturrell.com/artwork/boullees-eye/"
    }, {
      "name": "The Way Out",
      "lat": 38.2919,
      "lon": -122.4569,
      "year": 2005,
      "public": false,
      "links": "http://jamesturrell.com/work/the-way-out/"
    }, {
      "name": "Revised Outlook",
      "lat": 34.0194,
      "lon": -118.4903,
      "year": 2005,
      "public": false,
      "links": "http://archive.jamesturrell.com/artwork/revisedoutlook/"
    }],

    publicIcon: L.icon({
      iconUrl: 'images/public-icon.png',
      iconSize: 100,
      iconAnchor: L.point(50, 40),
      shadowUrl: 'images/icon-shadow.png',
      shadowAnchor: [40, 30],
      shadowSize: 105

    }),

    privateIcon: L.icon({
      iconUrl: 'images/public-icon.png',
      iconSize: 100,
      iconAnchor: L.point(50, 40),
      shadowUrl: 'images/icon-shadow.png',
      shadowAnchor: [40, 30],
      shadowSize: 105
    })
  });
});