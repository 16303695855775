define('skycoin/components/coin-link', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    to: null,
    size: null,

    didInsertElement: function didInsertElement() {

      var coin = this.$('.coinlink');
      var size = coin.width();

      coin.height(size);
      coin.width(size);
    }
  });
});